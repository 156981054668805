/**
 * タブとボタンを活性非活性を制御する
 * enable: true (STGにデプロイする際はtrueに設定する)
 *  disableTabNameとdisableButtonNameに含まれる機能が非活性になる
 * enable: false
 *  全機能活性状態になる
 */
export const inactiveFunctionSettings = Object.freeze({
    enable: false,
    disableTabName: [],
    disableButtonName: ['unnecessary-removal-ai', 'point-cloud-coloring', 'grid-interpolation', 'display-color', 'perimeter-line-create'],
});

/**
 * 穴補間の各機能の表示/非表示を制御する
 * ・true:表示
 * ・false：非表示
 */
export const isHoleInterpolationEnabled = Object.freeze(
  {
    polygon: true, // 多角形
    box: true, // 矩形
    circle: true // 円
  }
)